import React from 'react';
import ArticlePage from 'components/pages/ArticlePage';
import { Text, H1, P, A } from 'components/ui';

const AboutPage = () => (
  <ArticlePage title="About" description="">
    <Text>
      <H1>About Sergey Solovev</H1>
      <P>
        I got my first job as a software engineer back in 2007, three years
        before completing a degree in applied mathematics and computer science.
        From then on I developed several web apps and backend services,
        excercised my skills in databases performance optimization, end-to-end
        test automation and functional programming.
      </P>
      <P>
        Later I had an opportunity to build and launch an educational scheduling
        and resource planning software for one of the biggest{' '}
        <A href="https://spbu.ru" rel="noopener" target="_blank">
          universities
        </A>{' '}
        in Russia. Apart from serving thousands of students and lecturers every
        day, it helps the administrative staff to measure and improve resource
        utilization.
      </P>
      <P>
        Currently I’m responsible for front-end and user interface design at{' '}
        <A href="https://www.metric.ai" rel="noopener" target="_blank" nowrap>
          metric.ai.
        </A>
      </P>
    </Text>
  </ArticlePage>
);

export default AboutPage;
